<template>
  <div>
    <div v-bind:class="[ isAVideo ? 'video-wrapper' : '', 'rf-header']">
        <video v-if="isAVideo" autoplay muted loop playsinline>
          <source :src="headerFile.pathname | getAsset" type="video/mp4">
        </video>
        <header class="rf-header" v-bind:style= "[!isAVideo && headerFileSet ? {backgroundImage: `url(${currentImage})`} : {}]">
          <b-container class="h-100">
            <b-row class="h-100 align-items-center">
              <b-col cols="12" md="8" lg="6" class="align-items-center">
                <div class="info-text">
                  <div class="title" v-if="title">{{$t(title)}}</div>
                  <div class="building-name" v-if="buildingName">{{buildingName}}</div>
                  <div class="description" v-if="description">{{description}}</div>
                  <div class="description" v-if="secondaryDescription">{{secondaryDescription}}</div>
                  <div class="description" v-if="thirdDescription">{{thirdDescription}}</div>
                  <RFButton class="mt-5" v-if="showButton" :label="label" :background="color" :border-color="color" @on-click="$emit('click')"/>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </header>
    </div>
  </div>
</template>

<script>
import RFButton from "@/components/forms/RFButton"
import { headerTypes } from '@/enums'
import {mapState} from "vuex";
import moment from "moment";
import {headerService} from '@/services/header.service';
import {getAsset} from '@/helpers';

export default {
  name: 'Header',
  components: {
    RFButton
  },
  props: {
    title: {
      default: ''
    },
    buildingName: {
      default: ''
    },
    description: {
      default: ''
    },
    secondaryDescription: {
      default: ''
    },
    thirdDescription: {
      default: ''
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    label: {
      default: null,
    },
    color: {
      default: '#E26F5A'
    },
    image: {
      default: '/images/home_header.jpg',
    },
    video: {
      default: null,
    },
    type:{
      type:String,
      default:null
    },
    isVideo:{
      type:Boolean,
      default:false
    },
    background:{
      type:String,
      default:null
    }
  },
  data() {
    return {
      headerTypes,
      headerFile : null,
      isAVideo : this.isVideo,
      currentImage : this.image,
      headerFileSet : false
    }
  },
  computed: {
    ...mapState({
      headerFiles: state => state.adminStore.headerFiles,
    }),
    currentMonth() {
      return moment().format('YYYY-MM')
    }
  },
  async beforeMount() {
    if(this.type!=null){
      if(this.type!==this.headerTypes.PARTICULAR_ACCOMMODATION){
        const { data: headerFile, status } = await headerService.getImage(this.type)
        if(status === 200){
          this.headerFile = headerFile
        }
      }
      else{
        this.headerFile = {pathname: this.background}
      }
      this.checkHeaderFileExtension()
    }
    else{
      this.currentImage = this.image
      this.headerFileSet = true
    }
  },
  methods: {
    checkHeaderFileExtension(){
      let extension = this.headerFile.pathname.split('.')[1]
      if(extension === "mp4"){
        this.isAVideo = true
      }
      else{
        this.currentImage = getAsset(this.headerFile.pathname)
      }
      this.headerFileSet = true
    }
  }
}
</script>

<style lang="scss">
.video-wrapper {
  /* Will not allow the video to overflow the
  container */
  overflow: hidden;

  /* Centering the container's content vertically
  and horizontally */
  //text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

video {
  /** Simulationg background-size: cover */
  object-fit: cover;
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
}


.rf-header {
  width: 100%;
  position: relative;
  height: calc(80vh);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .info-text {
    color: $white;
    text-transform: uppercase;

    .title {
      @include font-style($montserrat-bold, 'bold', $font-38);
      text-transform: initial;
      line-height: 2.5rem;
    }
    .building-name {
      @include font-style($montserrat, 'bold', $font-50);
      line-height: .8em;
    }
    .description {
      @include font-style($montserrat, 'medium', $font-16);
      text-transform: initial;
      margin-top: 20px;
    }
  }

}
</style>

