import { getItem, getAdminItems, putAdminData } from '@/utils/network'

const getImage = async (headerType) => await getItem(`/header/image?headerType=${headerType}`)
const getImages = async () => await getAdminItems(`/header/images`)
const putImage = async headerForm => await putAdminData(`/header/image`, headerForm)


export const headerService = {
  getImage,
  getImages,
  putImage
}
