<template>
  <div class="privacy-policy-page">
    <Header/>
    <div class="rf-privacy-policy-title-div rf-white-background pt-5 pb-2">
      <b-container>
        <b-row>
          <b-col>
            <h1 class="text title">Privacy policy</h1>
            <div class="privacy-policy-category-text">
              <p class="text">This privacy policy is provided for under art. 13, Regulation (EU) 2016/679 (“GDPR”), to the users
                visiting the following web site: <router-link to="/">www.roofinders.com</router-link> (the “website”).</p>
              <p class="text">It describes the processing activities carried out when you visit our website.
                Specifically, it regards the content of the website only and it does not concern any other web
                site that might be visited by users by clicking on external links which may be present on the
                website</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="rf-privacy-policy-category">
      <b-container>
        <h1 class="privacy-policy-category-title">1. Who is the data controller ?</h1>
        <div class="privacy-policy-category-text privacy-policy-full-paragraph-div">
          <p class="text-line"><b>Roofinders S.r.l.</b> with registered office in Milan, 43, Alessandro Manzoni Street, VAT no.
            11182550969, in the name of his legal representative pro tempore is the data controller of your
            personal data (“<b>Roofinders</b>” or the “<b>Data Controller</b>”).</p>
          <p class="text-line">You may contact the Data Controller writing at the following email address:
            <a :href="`mailto:${RFData.mail}`">{{RFData.mail}}</a>
          </p>
        </div>
      </b-container>
    </div>
    <div class="rf-privacy-policy-category">
      <b-container>
        <h1 class="privacy-policy-category-title">2. What does “personal data” mean?</h1>
        <div class="privacy-policy-category-text">
          <div class="privacy-policy-full-paragraph-div">
            <p class="text-line">“<b>Personal data</b>” means any information relating to an identified or identifiable natural person,
              who can be identified directly or indirectly; in this case, you, who are visiting the website (the
              “<b>Data</b>”).</p>
            <p class="text-line">In particular, the Data Controller processes or may process the following Data:</p>
          </div>
          <div class="privacy-policy-category-list">
            <ul>
              <li v-for="data in dataProcessed" class="text-line" :key="data.description">{{data.description}}</li>
            </ul>
          </div>
          <p class="text-line">When you visit the website, Roofinders may collect data either indirectly (e.g. by tracking the
            IP address and the URL of your device), or directly (e.g. when you voluntarily provide us with
            your data by filling in forms specifically provided).</p>
        </div>
      </b-container>
    </div>
    <div class="rf-privacy-policy-category">
      <b-container>
        <h1 class="privacy-policy-category-title">3. Which are the purposes for processing your personal data and the relevant legal basis?</h1>
        <div class="privacy-policy-category-text">
          <div id="privacy-policy-category-list-item-3" class="privacy-policy-category-list">
            <ol>
                <li v-for="purpose in purposes" class="text-line" :key="purpose.description">
                  <div>
                    <p>{{purpose.description}}</p>
                    <p>{{purpose.legal_basis}}</p>
                  </div>
                </li>
            </ol>
          </div>
          <div class="privacy-policy-full-paragraph-div">
            <p class="text-line">In any case, we are committed to ensuring that the information collected and used is
              appropriate to the purposes of the Data Controller and that this does not result in an invasion
              of your personal sphere.</p>
            <p class="text-line">For your clarity, the following table provides details of the purposes for which your data is
              processed, whether providing your data is mandatory, the consequences of your refusal to
              provide the data, and the legal basis for the processing.</p>
          </div>
          <div class="privacy-policy-table text-line">
            <b-table bordered :items="tableItems" :fields="tableFields"></b-table>
          </div>
        </div>
      </b-container>
    </div>
    <div class="rf-privacy-policy-category">
      <b-container>
        <h1 class="privacy-policy-category-title">4. Cookies</h1>
        <div class="privacy-policy-category-text privacy-policy-full-paragraph-div">
          <p class="text-line">Cookies are small text files sent by a web server (e.g. the site) to the user's Internet browser,
            automatically stored on the computer and automatically sent back to the server each time the
            site is accessed.</p>
          <p class="text-line">By default, almost all web browsers are set to automatically accept cookies. Visitors can set
            their device's browser to accept/reject all cookies or to display a notice whenever a cookie is
            proposed, in order to assess whether or not to accept it. The user can, however, change the
            default configuration and disable cookies (i.e. block them permanently), setting the highest
            level of protection.</p>
          <p class="text-line">For any other information on the characteristics, types, use and ways to remove, delete or
            disable cookies on the Website, please read the specific "<i>Cookie Policy</i>".</p>
        </div>
      </b-container>
    </div>
    <div class="rf-privacy-policy-category">
      <b-container>
        <h1 class="privacy-policy-category-title">5. Where do we transfer your data?</h1>
        <div class="privacy-policy-category-text privacy-policy-full-paragraph-div">
          <p class="text-line">The website is located on a server in the EU.</p>
          <p class="text-line">Your data may be transferred to parties which Roofinders engages for the performance of
            activities necessary for achieving the purposes indicated and described in paragraph 3 above,
            including external companies which offer Roofinders maintenance and development services
            for the website and, in general, IT services, specifically appointed as data processors according
            to art. 28, GDPR. Moreover, where such communication is possible or required by law, your
            data can be communicated to other companies, competent Authorities or Public Bodies
            which will process them for their own purposes as independent data controllers.</p>
          <p class="text-line">The list of data processors can be requested by contacting the Data Controller at the email
            address indicated in previous paragraph 1.</p>
          <p class="text-line">In any case, your data will not be transferred outside the European Economic Area nor
            disseminated.</p>
        </div>
      </b-container>
    </div>
    <div class="rf-privacy-policy-category">
      <b-container>
        <h1 class="privacy-policy-category-title">6. How long we retain your personal data?</h1>
        <div class="privacy-policy-category-text privacy-policy-full-paragraph-div">
          <p class="text-line">We process your data for the time strictly necessary to achieve the purposes indicated in
            paragraph 3 above. The retention periods of the data indirectly collected by the Data Controller
            through cookies are listed in the Cookie Policy.</p>
          <p class="text-line">For each of the purposes set forth in paragraph 3, the Data will be stored in accordance with
            the following: 3.1) the data are immediately anonymized. However, in case of detection of
            cybercrimes, the data will be processed for the management of the same for the time
            necessary for this purpose; 3.2) the data will be kept for the time strictly necessary to meet
            your requests and as long as there are obligations connected to your request; subsequently,
            they will be kept for a maximum period of 10 years exclusively for purposes connected with the
            fulfilment of legal obligations or the defence of legal rights; 3.3) the data will be kept for the
            time strictly necessary to follow up any legal or regulatory obligations imposed on the Data
            Controller and in any case no more than 10 years from the end of your relationship with
            Roofinders; 3.4) the data will be kept until your consent is revoked or your right to object is
            exercised while the Data relating to your preferences will be kept for no longer than 24 months
            from their collection; and 3.5) the data will be kept until your consent is revoked or your right
            to object is exercised.</p>
          <p class="text-line">Finally, we reserve the right to store <i>log data</i> for a longer period of time to manage potential
            crimes against the website (e.g. hacking).</p>
        </div>
      </b-container>
    </div>
    <div class="rf-privacy-policy-category">
      <b-container>
        <h1 class="privacy-policy-category-title">7. Which are your rights as data subject?</h1>
        <div class="privacy-policy-category-text">
          <p class="text-line">In your quality as data subject, according to the conditions laid down by the GDPR, you have
            the right to:</p>
          <div class="privacy-policy-category-list">
            <ul>
              <li v-for="right in rights" class="text-line" :key="right.description">{{right.description}}</li>
            </ul>
          </div>
          <div class="privacy-policy-full-paragraph-div">
            <p class="text-line">Finally, you may at any time submit a complaint to the Italian Data Protection Authority or any
              other competent data protection authority.</p>
            <p class="text-line">In order to obtain further information on your rights, you may contact the Data Controller at
              the e-mail address specified in previous paragraph 1.</p>
          </div>
        </div>
      </b-container>
    </div>
    <div class="rf-privacy-policy-category">
      <b-container>
        <h1 class="privacy-policy-category-title">8. Final provisions</h1>
        <div class="privacy-policy-category-text privacy-policy-full-paragraph-div">
          <p class="text-line">The Data Controller reserves the right to modify and/or update this privacy policy.</p>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'PrivacyPolicy',
  components: {
    Header
  },
  data() {
    return {
      RFData:{
        mail : 'privacy@roofinders.com'
      },
      dataProcessed:[
        {
          description: 'The IP address, domain name and URL of the device being used'
        },
        {
          description: 'The browsing data'
        },
        {
          description: 'The browsing history'
        },
        {
          description: 'Your first and last name'
        },
        {
          description: 'Your contact details (i.e., email address)'
        },
        {
          description: 'Data relating to your preferences, habits, needs and/or consumer choices'
        },
        {
          description: 'Data relating to your requests, including the period of your stay'
        },
        {
          description: 'Any other data you voluntarily provide'
        }
      ],
      purposes:[
        {
          description: 'The Data Controller collects and processes your data in order to carry out activities\n' +
            'concerning the management and administration of the website, as well as in order to\n' +
            'improve the visitor\'s browsing experience, pursuant to art. 6, par. 1, lett. f), GDPR.',
          legal_basis: ''
        },
        {
          description: 'The Data Controller collects and processes your data in order to follow up on your requests\n' +
            'forwarded through the formats available on the Website, pursuant to art. 6, par. 1, lett. b),\n' +
            'GDPR.',
          legal_basis: 'For this purpose, the provision of your data is optional; however, in case of refusal to\n' +
            'provide the same, Roofinders will not be able to meet your requests.'
        },
        {
          description: 'The Data Controller collects and processes your data in order to comply with legal and\n' +
            'regulatory obligations imposed on the Data Controller or provisions of public authorities,\n' +
            'pursuant to art. 6, par. 1, lett. c), GDPR.',
          legal_basis: 'For this purpose, the provision of data is mandatory. In case of failure to provide the data,\n' +
            'the Data Controller will not be able to guarantee the browsing of the website, as well as\n' +
            'the fulfilment of your possible requests.'
        },
        {
          description: 'Subject to your express and specific consent, the Data Controller will collect and process\n' +
            'your data for sending communications of a commercial nature for the purpose of direct\n' +
            'offer of its products and/or services in line with your needs identified on the basis of your\n' +
            'profile and/or your personality, your preferences, habits, needs and / or consumer choices,\n' +
            'pursuant to art. 6, par. 1, lett. a), GDPR.',
          legal_basis: 'For this purpose, the provision of your data is optional; however, in case of refusal to\n' +
            'provide the same, Roofinders will not be able to send you communications of a\n' +
            'commercial nature in line with your needs.'
        },
        {
          description: 'Subject to your express and specific consent, the Data Controller will collect and process\n' +
            'your data for sending newsletters containing updates on products and/or services\n' +
            'provided by Roofinders, pursuant to art. 6, par. 1, lett. a), GDPR.',
          legal_basis: 'For this purpose, the provision of your data is optional; however, in case of refusal to\n' +
            'provide the same, Roofinders will not be able to send you newsletters.'
        }
      ],
      tableFields: ['purpose_of_processing', 'nature_of_the_provision_of_the_data', 'consequences_of_your_refusal_to_provide_personal_data', 'legal_basis_for_the_processing'],
      tableItems: [
        { purpose_of_processing: 'Website management and administration', nature_of_the_provision_of_the_data: 'Mandatory', consequences_of_your_refusal_to_provide_personal_data: 'Impossibility for the Data Controller to guarantee the browsing on the website', legal_basis_for_the_processing: 'Legitimate interest of the Data Controller' },
        { purpose_of_processing: 'Fulfilment of data subjects’ requests', nature_of_the_provision_of_the_data: 'Optional', consequences_of_your_refusal_to_provide_personal_data: 'Impossibility for the Data Controller to follow up on your requests', legal_basis_for_the_processing: 'Management of data subjects’ requests' },
        { purpose_of_processing: 'Fulfilment of legal obligations', nature_of_the_provision_of_the_data: 'Mandatory', consequences_of_your_refusal_to_provide_personal_data: 'Impossibility for the Data Controller to guarantee you the browsing of the website, as well as the fulfilment of your possible requests', legal_basis_for_the_processing: 'Fulfilment of legal or regulatory obligations' },
        { purpose_of_processing: 'Sending customized marketing communications', nature_of_the_provision_of_the_data: 'Optional', consequences_of_your_refusal_to_provide_personal_data: 'Impossibility for the Data Controller to send you communications of a commercial nature in line with your needs', legal_basis_for_the_processing: 'Your consent' },
        { purpose_of_processing: 'Sending newsletters', nature_of_the_provision_of_the_data: 'Optional', consequences_of_your_refusal_to_provide_personal_data: 'Impossibility for the Data Controller to send you newsletters', legal_basis_for_the_processing: 'Your consent' },
      ],
      rights:[
        {
          description : 'Access your data and obtain a copy of the same'
        },
        {
          description : 'Ask for the rectification and the update of your data, where inaccurate or incomplete'
        },
        {
          description : 'Ask for the erasure of your data'
        },
        {
          description : 'Restrict the processing of your data'
        },
        {
          description : 'Ask for data portability'
        },
        {
          description : 'Oppose the processing of your data'
        },
        {
          description : 'Revoke the consent to the processing previously granted'
        }
      ]
    }
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.privacy-policy-page{

  .text {
    @include font-style($montserrat, 'medium', $font-16);
    color: $dark-blue;
    margin: 20px 0 0 0;

    &.suptitle {
      @include font-style($montserrat-bold, 'bold', $font-34);
      margin: 0;
    }
    &.title {
      @include font-style($montserrat-bold, 'bold', $font-44);
      margin: 0;
    }
  }

  .rf-privacy-policy-category{
    position: relative;
    padding-top: 2%;

    .privacy-policy-category-title{
        @include font-style($montserrat-bold, 'bold', $font-26);
        color: $dark-blue;
    }

    .privacy-policy-category-text{
        margin-top: 40px;

        .text-line {
          @include font-style($montserrat, 'medium', $font-16);
          color: $dark-blue;
          margin: 0;
        }
    }

    .privacy-policy-category-list{
      padding-top: 3%;
    }

    .privacy-policy-full-paragraph-div{
      p{
        padding-bottom: 2%;
      }
    }

    .privacy-policy-table{
      .table{
        color : $dark-blue;
      }
    }

  }

  .rf-privacy-policy-title-div{

    .privacy-policy-category-text{
      margin-top: 40px;

      .text-line {
        @include font-style($montserrat, 'medium', $font-16);
        color: $dark-blue;
        margin: 0;
      }
    }

  }

}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .privacy-policy-page {
    .rf-privacy-policy-category {
      .privacy-policy-category-text {
        width: 80%;
      }

    }
    .rf-privacy-policy-title-div {
      .privacy-policy-category-text {
        width: 80%;
      }

    }
  }
}

</style>
